


























































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '../messages/BaseMessage.vue';

@Component({
  name: 'reply-message',
  components: {BaseMessage}
})
export default class ReplyMessage extends Vue {
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;

  get replyMessage() {
    return this.message.data.repliedMessage
  }

  get replySender() {
    return this.replyMessage?.sender.name;
  }

  get replyText() {
    return this.replyMessage?.text;
  }

  get maxWidth() {
    if (!this.replyMessage) {
      return 0
    }
    const size = this.replyMessage.image.size;
    const ratio = size.width / size.height;
    return 46 * ratio;
  }

  get imageUrl() {
    return this.replyMessage?.image.url;
  }

  get replyMessageType() {
    return this.replyMessage?.type;
  }

  get latitude() {
    return this.replyMessage?.geopoint?.latitude || 0
  }

  get longitude() {
    return this.replyMessage?.geopoint?.longitude || 0
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
