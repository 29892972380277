




































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';
import {messageType} from '@/domain/model/types';

@Component({
  name: 'system-message',
  components: {BaseMessage}
})
export default class SystemMessage extends Vue {
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop({default: true}) withMenu!: boolean;

  get messageTypes() {
    return messageType;
  }

  get text() {
    let text: string | null;
    switch (this.message.data.type) {
      case this.messageTypes.ADD_PERSON_TO_GROUP:
        text = `<span class="link-text">${this.message.data.sender.name}</span> added <span class="link-text">${this.message.data.recipient.name}</span> to this chat`;
        break;
      default:
        text = this.message.data.text;
    }
    return text;
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
