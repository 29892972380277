



import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({name: 'google-map-marker'})
export default class GoogleMapMarker extends Vue {
  @Prop() map: any
  @Prop() position: any

  // todo: temporary solution
  POINT_MARKER_ICONS: any = {
    selectedLocation: 'suggest_position.webp',
    myLocation: 'suggest_position.webp'
  }

  currentMarker: any = null

  @Watch('position')
  onChanged(value, oldValue) {
    if (this.currentMarker) {
      this.currentMarker.setPosition(new google.maps.LatLng(parseFloat(value.lat), parseFloat(value.lng)))
    }
  }

  created() {
    const {map, position} = this
    this.currentMarker = new google.maps.Marker({
      position: new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng)),
      map: map,
      icon: require(`../../assets/img/${this.POINT_MARKER_ICONS.selectedLocation}`)
    })
  }
}
