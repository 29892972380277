












































import SearchView from '@/components/SearchView.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {BusinessContact} from '@/domain/model/types';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {Getter} from 'vuex-class';

@Component({name: 'contacts-list', components: {AvatarWithStatus, SearchView}})
export default class ContactsList extends Vue {
  @Prop({
    default: () => {
      return []
    }
  }) filterIds!: string[];
  @Prop({default: true}) multi!: boolean;
  @Prop({default: false}) withGroups!: boolean;
  @Prop() items?: any[];
  @Prop({default: 1}) contactType!: number;
  @Prop({default: false}) withCustomer!: boolean;
  @Prop({default: false}) hideAdd!: boolean;
  @Prop({default: false}) hideSearch!: boolean;
  @Prop({default: false}) selectedWithSearch!: boolean;

  @Getter customers

  customContacts: { data: any, selected: boolean }[] = [];
  customContactsOriginal: { data: any, selected: boolean }[] = [];
  lastSelectedContact: BusinessContact | null = null

  get contacts() {
    return this.items ? this.items : ( this.withGroups ? directoryStore.allContacts : directoryStore.customContacts)
  }

  get contactItems() {
    return this.customContacts;
  }

  get includedContacts() {
    return this.customContacts.reduce((count, item) => {
      return item.selected ? ++count : count;
    }, 0);
  }

  get selectedContacts() {
    if(this.selectedWithSearch){
      return this.customContactsOriginal.filter((item) => item.selected).map((item) => item.data);
    }else{      
      return this.contactItems.filter((item) => item.selected).map((item) => item.data);    
    }
  }

  get scrollClass() {
    const classList: string[] = ['my-6 custom-scroll', 'pa-0 custom-scroll']
    if (!this.multi && this.includedContacts > 0) {
      classList.push('item-selected')
    }
    return classList.join(' ')
  }

  @Watch('contacts')
  onContactsChanged(_, before) {
    if (!before) {
      return
    }
    this.initContacts()
  }

  photoUrl(item) {
    return item.photoUrl && item.photoUrl.thumbnail;
  }

  isOnline(contact) {
    const item = contact.associate || contact;
    const status = item.status;
    return status && status.online;
  }

  search(term) {
    if (term === null || term === '') {
      this.customContacts = this.customContactsOriginal;
      return;
    }
    const searchString = term.toLowerCase();
    this.customContacts = this.customContactsOriginal.filter((item) => {
      return item.data.name?.toLowerCase().startsWith(searchString) || item.data.fullName?.toLowerCase().startsWith(searchString);
    });
  }

  onContactSelected(contact) {
    if (!this.multi && this.lastSelectedContact && this.lastSelectedContact.id !== contact.data.id) {
      // uncheck selected
      const updatedContact = {data: this.lastSelectedContact, selected: false};
      const predicate = (item) => item.data.id === this.lastSelectedContact!.id;
      const indexOf1 = this.customContacts.findIndex(predicate);
      const indexOf2 = this.customContactsOriginal.findIndex(predicate);
      this.customContacts.splice(indexOf1, 1, updatedContact);
      this.customContactsOriginal.splice(indexOf2, 1, updatedContact);
    }    

    const indexOf1 = this.customContacts.indexOf(contact);
    const indexOf2 = this.customContactsOriginal.indexOf(contact);
    const item = {...contact, selected: !contact.selected};
    this.customContacts.splice(indexOf1, 1, item);
    this.customContactsOriginal.splice(indexOf2, 1, item);

    this.lastSelectedContact = contact.data

    this.$emit('contact-selected', this.selectedContacts);    
  }

  initContacts() {
    var contacts = this.contacts
    if(this.withCustomer){
      var customers = this.customers .filter((item) => {
          return item.id != null
        })
        .filter((item) => {
          return !this.filterIds.includes(item.id)
        })
        .map((item) => {
          return {...item, isCustomer: true}
        });
      contacts = [...this.contacts, ...customers];
      this.customContacts = contacts
        .filter((item) => {
          const hasUid = item.type === 1 ? this.filterIds.includes(item.associate!.id!) : false
          if(item.hasOwnProperty('isCustomer')){
            return item
          }else{
            return !this.filterIds.includes(item.id!) && !hasUid
          }
        })
        .map((item) => {
          return {data: item, selected: false};
        });
    }else{
      this.customContacts = contacts
        .filter((item) => {
          const hasUid = item.type === 1 ? this.filterIds.includes(item.associate!.id!) : false
          return !this.filterIds.includes(item.id!) && !hasUid
        })
        .map((item) => {
          return {data: item, selected: false};
        });
    }
    this.customContactsOriginal = Object.assign([], this.customContacts);
  }

  created() {
    this.initContacts()
  }
}
