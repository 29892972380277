














































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {compareMembersFn, firstWord, formatTimestamp, getFirstName} from '@/utils/helpers';

@Component({
  name: 'archive-case-list-item',
  components: {AvatarWithStatus, GroupAvatar},
  filters: { formatTimestamp}
})
export default class ArchiveCaseListItem extends Vue {
  @Prop({default: false}) groupChat?: boolean;
  @Prop() group?: any;
  @Prop() item?: any;
  @Prop() userId?: string;
  @Prop({default: false}) online?: boolean;
  @Prop({default: false}) selected?: boolean;
  @Prop({default: false}) exportMode?: boolean;

  get name() {
    return this.item?.customer?.name || ''
  }

  get checked() {
    return !!this.item?.checked
  }

  set checked(value) {
    this.item.checked = value
  }

  get hasTitle() {
    return !!this.item?.title
  }

  get title() {
    if (this.hasTitle) {
      return this.item?.title;
    }
    if(this.isGroup){
      const custId = this.item.customer?.id
      let titleText = this.item.customer?.name           
      
      //#BAW-983 - customer name + other associates first name      
      const memberUids = Object.keys(this.item?.members || {}).sort();
      memberUids.forEach(uid => {
        const member = this.item.members[uid];        
        if (member.uid != custId) {          
          titleText =  titleText + ', ' + getFirstName(member.name);
        }        
      });
      
      return titleText
    }
    return this.item?.customer?.name;
  }

  get subtitle() {
    return this.item?.associate?.name
  }

  get isGroup(): boolean {
    return this.item?.subtype !== 1;
  }

  messageText(message): string {
    return `${this.userId === message.sender.uid ? 'You' : firstWord(message.sender.name)}: ${message.text}`;
  }

  get customerPhotoUrl() {
    return this.item?.customer?.photoUrl?.thumbnail;
  }

  get imagesUrls() {
    return Object.values(this.item?.members || [])
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);
  }

  get imagesUrlsCnt() {
    let imageUrl = Object.values(this.item?.members || [])
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);

    return imageUrl.length > 0;
  }
}
