





















































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {compareMembersFn, firstWord, formatTimestamp} from '@/utils/helpers';

@Component({
  name: 'inner-chat-list-item',
  components: {AvatarWithStatus, GroupAvatar},
  filters: {
    formatTimestamp
  }
})
export default class InnerChatListItem extends Vue {
  @Prop({default: false}) groupChat?: boolean;
  @Prop() item?: any;
  @Prop() abbreviation?: string;
  @Prop() userId?: string;
  @Prop({default: false}) online?: boolean;
  @Prop({default: false}) selected?: boolean;

  get hasTitle() {
    return !!this.item.title
  }

  get extendedGroupChat() {
    return this.item && this.item.subtype === 3
  }

  get title() {
    if (this.hasTitle) {
      return this.item.title
    }
    if (this.extendedGroupChat) {
      return Object.values(this.item.members).map((item: any) => item.name).sort().join(', ')
    }
    return this.item?.from.uid === this.userId ? this.item?.to.name : this.item?.from.name
  }

  get subtitle() {
    return this.item.from.uid === this.userId ? this.item.from.name : this.item.to.name
  }

  get showSubtitle() {
    return !this.hasTitle && !this.extendedGroupChat
  }

  get imageUrl() {
    const fromId = this.item.from && this.item.from.uid;
    const toId = this.item.to && this.item.to.uid;
    const uid = fromId === this.userId ? toId : fromId
    const member = this.item.members && this.item.members[uid];
    return (member && member.photoUrl) ? member.photoUrl.thumbnail : null;
  }

  get imagesUrls() {
    return Object.values(this.item.members)
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);
  }

  get imagesUrlsCnt() {
    let imageUrl = Object.values(this.item.members)
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.thumbnail);

    return imageUrl.length > 0;
  }

  get unreadCount(): number {
    return this.item.unread && this.item.unread[this.userId!] || 0;
  }

  messageText(message): string {
    return `${this.userId === message.sender.uid ? 'You' : firstWord(message.sender.name)}: ${message.text}`;
  }
};
