












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'icon-button'})
export default class IconButton extends Vue {
    @Prop({required: true}) icon!: string;
}
