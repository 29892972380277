










import {Loader} from '@googlemaps/js-api-loader';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {GoogleMapStatus} from "@/domain/model/types";
import {googleMapStore} from "@/store/modules/googleMaps";
import {Getter} from "vuex-class";

@Component({name: 'google-map-simple-loader'})
export default class GoogleMapSimpleLoader extends Vue {
  @Prop() mapConfig: any
  @Prop() apiKey?: string

  map: any = null

  initMap() {
    const mapContainer: any = this.$refs.googleMap;
    this.map = new google.maps.Map(mapContainer, this.mapConfig);
  }

  @Watch('googleMapScriptStatus')
  onGMSStatusChanged(value) {
    if (value === GoogleMapStatus.LOADED) {
      this.initMap()
    }
  }

  @Getter googleMapScriptStatus

  async mounted() {
    if (this.googleMapScriptStatus === GoogleMapStatus.LOADED) {
      this.initMap()
    } else {
      await googleMapStore.loadMap()
    }
  }
}
