var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"right":_vm.right,"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"more-option":_vm.moreOption,"with-menu":_vm.withMenu,"with-time-icon":_vm.withTimeIcon,"channel":_vm.channel},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var showRight = ref.showRight;
      var messageText = ref.messageText;
      var timestamp = ref.timestamp;
      var timestampColor = ref.timestampColor;
      var timestampIcon = ref.timestampIcon;
      var timestampStyle = ref.timestampStyle;
      var color = ref.color;
      var textColorClass = ref.textColorClass;
      var message = ref.message;
      var cardStyle = ref.cardStyle;
      var paddingClass = ref.paddingClass;
      var showTimestampIcon = ref.showTimestampIcon;
return [_c('v-card',_vm._g({staticClass:"pointer",class:[textColorClass, {'image-outline': _vm.loading}],style:(cardStyle),attrs:{"flat":"","width":_vm.width,"height":_vm.height,"max-width":_vm.maxWidth,"max-height":_vm.maxHeight}},_vm.on),[_c('v-img',{attrs:{"src":_vm.imageUrl,"width":_vm.width,"height":_vm.height,"max-width":_vm.maxWidth,"max-height":_vm.maxHeight,"contain":""},on:{"load":_vm.onLoaded,"error":_vm.onError},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","color":"primary"}})],1)]},proxy:true}],null,true)},[(_vm.forwarded)?_c('div',{staticClass:"primary caption pa-1",class:[textColorClass]},[_c('div',[_vm._v("Forwarded message")]),_c('div',[_vm._v("From "+_vm._s(message.data.forwardedMessage.sender.name))])]):_vm._e(),(timestamp)?_c('div',{staticClass:"px-2 py-1 timestamp",class:_vm.isSMS ? (showRight ? 'chat-type-sms': 'sms-recevied') : 'primary'},[_c('span',{class:showRight ? 'right': 'left',style:(timestampStyle)},[_vm._v(_vm._s(timestamp))]),(showTimestampIcon && showRight)?_c('v-img',{staticClass:"ml-1",attrs:{"src":require(("../../assets/img/" + timestampIcon)),"max-width":"15","max-height":"8"}}):_vm._e()],1):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }