var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"right":_vm.right,"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"more-option":_vm.moreOption,"with-menu":_vm.withMenu,"with-time-icon":_vm.withTimeIcon,"channel":_vm.channel},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var showRight = ref.showRight;
              var messageText = ref.messageText;
              var timestamp = ref.timestamp;
              var timestampColor = ref.timestampColor;
              var timestampIcon = ref.timestampIcon;
              var color = ref.color;
              var backgroundClass = ref.backgroundClass;
              var textColorClass = ref.textColorClass;
              var textPositionClass = ref.textPositionClass;
              var message = ref.message;
              var paddingClass = ref.paddingClass;
              var cardStyle = ref.cardStyle;
              var edited = ref.edited;
              var showTimestampIcon = ref.showTimestampIcon;
return [_c('v-card',{class:[paddingClass, textColorClass, backgroundClass],style:(cardStyle),attrs:{"flat":"","max-width":"250","color":color}},[_c('v-card-text',{class:['pa-0', textPositionClass]},[_c('v-sheet',{staticClass:"contact-area"},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"primary","width":"42","height":"42"}},[_c('v-img',{attrs:{"src":_vm.contactPhotoUrl}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contact-card-title"},[_vm._v(" "+_vm._s(_vm.contactName)+" ")]),_c('v-list-item-subtitle',{staticClass:"contact-card-subtitle"},[_vm._v(" "+_vm._s(_vm.contactPosition)+" ")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-0 ma-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"view-contact text-capitalize",class:showRight ? 'sender' : 'recipient',attrs:{"text":"","depressed":"","ripple":false},on:{"click":function($event){return _vm.$emit('on-view-contact', _vm.contact)}}},[_vm._v("View contact ")]),_c('v-spacer')],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }