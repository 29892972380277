var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"right":_vm.right,"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"more-option":_vm.moreOption,"with-menu":_vm.withMenu,"with-time-icon":_vm.withTimeIcon,"channel":_vm.channel},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var showRight = ref.showRight;
  var messageText = ref.messageText;
  var timestamp = ref.timestamp;
  var timestampColor = ref.timestampColor;
  var timestampIcon = ref.timestampIcon;
  var color = ref.color;
  var timestampStyle = ref.timestampStyle;
  var cardStyle = ref.cardStyle;
  var showTimestampIcon = ref.showTimestampIcon;
return [_c('v-card',{style:(cardStyle),attrs:{"flat":"","max-width":"420"}},[_c('v-card-actions',{staticClass:"primary white--text"},[_c('span',[_vm._v(_vm._s(messageText))])]),_c('v-img',[_c('google-map-simple-loader',{attrs:{"map-config":_vm.mapConfig,"api-key":_vm.mapApiKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var map = ref.map;
return [_c('google-map-marker',{attrs:{"position":_vm.marker.position,"map":map}})]}}],null,true)}),_c('div',{staticClass:"px-2 py-1 primary timestamp"},[_c('span',{class:showRight ? 'right': 'left',style:(timestampStyle)},[_vm._v(_vm._s(timestamp))]),(showTimestampIcon && showRight)?_c('v-img',{staticClass:"ml-1",attrs:{"src":require(("../../assets/img/" + timestampIcon)),"max-width":"15","max-height":"8"}}):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }