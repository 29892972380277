var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"kind":_vm.kind,"show-avatar":false,"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu,"centered":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var showRight = ref.showRight;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var message = ref.message;
var cardStyle = ref.cardStyle;
var messageClass = ref.messageClass;
return [_c('v-card',{class:[messageClass],style:(cardStyle),attrs:{"flat":"","max-width":"500","color":"#eeeeee"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_vm._v(" Personal Data Request is sent to "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(message.data.recipient.name))])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }