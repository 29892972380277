


























































































































































































































































































































































































































































































































































































































































































































































import ChatInfoUser from '@/components/ChatInfoUser.vue';
import NoMessages from '@/components/NoMessages.vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Action, Getter, Mutation} from 'vuex-class';
import IconButton from '@/components/IconButton.vue';
import DialogNotificationSettings from '@/components/profile/DialogNotificationSettings.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {chatStore} from '@/store/modules/chat/ChatStore';
import DialogAddChatMembersAll from '@/components/chats/DialogAddChatMembersAll.vue';
import DialogAddChatMembers from '@/components/chats/DialogAddChatMembers.vue';
import {compareMembersFn, counterFormatter, formatMuted, formatNoteDate} from '@/utils/helpers';
import {phoneFormat} from '@/utils/helpers';
import {Unsubscribe} from 'firebase/firestore';
import {GoToOptions} from 'vuetify/types/services/goto';
import DialogMuteTimeSettings from '@/components/profile/DialogMuteTimeSettings.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import Notifications from '@/components/mixins/Notifications';
import EmptyState from '@/components/EmptyState.vue';
import {mixins} from 'vue-class-component';

@Component({
  name: 'chat-info',
  components: {
    PopupMenu,
    DialogMuteTimeSettings, DialogConfirm,
    DialogAddChatMembersAll, DialogAddChatMembers, DialogNotificationSettings, IconButton, ChatInfoUser, NoMessages, EmptyState
  },
  filters: {counterFormatter, formatNoteDate, formatMuted}
})
export default class ChatInfo extends mixins(Notifications) {

  @Prop({default: false}) value!: boolean;
  @Prop({default: false}) blockedUser!: boolean;
  @Prop() userId!: string;
  @Prop() textSession: any;
  @Prop({default: 'info'}) windowKey!: string;
  @Prop({default: 'chat'}) mode!: string;
  @Prop({default: false}) toggle!: boolean;
  @Prop({default: false}) isHideCustom!: boolean;

  @Getter caseNotes!: any[];
  @Getter customerNotes;
  @Mutation setSelectedCustomer;

  membersActive: boolean = false;

  windows: any = {
    info: {index: 0, title: 'Case information'},
    caseNotes: {index: 1, title: 'Case notes'},
    customerNotes: {index: 2, title: 'Customer notes'},
    noteReplies: {index: 3, title: 'Replies'},
    viewPersonalData: {index: 4, title: 'Personal data'}
  };

  currentWindow: string = ''
  navHistory: string[] = [];
  backTitles: string[] = [];

  selectedNote: any | null = null;
  editNoteId: string | null = null;
  noteText: string = '';
  noteReplyText: string = '';
  editReplyId: string | null = null;
  showMuteChatDialog: boolean = false;
  editTitle: boolean = false;
  newChatTitle: string = '';
  showAddChatMembersAllDialog: boolean = false
  showAddChatMembersDialog: boolean = false
  createGroup: boolean = false
  unsubscribeReplies: Unsubscribe | null = null
  dialogConfirm: boolean = false
  dialogConfirmText: string = ''
  dialogConfirmBtn: string = 'Yes'
  onConfirm?: any;

  compareFn = (a, b) => {
    let result = Number(this.isStarred(b)) - Number(this.isStarred(a))
    if (!!result) {
      return result
    }
    result = a.createdDate?.seconds - b.createdDate?.seconds
    return result
  };

  get options(): GoToOptions {
    return {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    }
  }

  get noteReplies() {
    return chatStore.noteReplies
  }

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }

  get showBack() {
    return this.navHistory.length
  }

  get backTitle() {
    return this.backTitles[this.backTitles.length - 1]
  }

  get mutedFor() {
    return this.textSession?.mutedBy && this.textSession.mutedBy[this.userId]
  }

  get isActiveChat() {
    return this.textSession?.type === 4;
  }

  get isInnerChat() {
    return this.textSession?.type === 3
  }

  get isGroup() {
    return this.textSession?.subtype > 1
  }

  get isRequest() {
    return this.textSession?.case?.status === 1 // this.$consts.CASE_REQUESTED todo: fix plugin not recognized
  }

  get isAccepted() {
    return this.textSession?.case?.status === 2;  // todo: replace with constants
  }

  get isRejected() {
    return this.textSession?.case?.status === 3
  }

  get isArchive() {
    return this.mode == 'archive'
  }

  get chatInfoTitle() {
    const title = this.windows[this.currentWindow]?.title;
    return this.currentWindow !== 'noteReplies' ? title : `${title} ${chatStore.repliesCount}`
  }

  get title() {
    return this.textSession?.title ? this.textSession?.title : ''
  }

  set title(title: string) {
    this.newChatTitle = title
  }

  get chatId() {
    return this.textSession?.id;
  }

  get customer() {
    return this.textSession.customer;
  }

  get contact() {
    return this.textSession.contact
  }

   get contactPhone() {
    return phoneFormat(this.textSession.customer.phoneNumber);
  }

  get customerDescription() {
    return this.customer && `${this.customer.email}` || '';
  }

  get isSingleCustomer() {    
    const customerList: any =  Object.values(this.textSession?.members).filter(
      (item: any) => (item.type == 2)
    );

    if(customerList.length == 1)  {
      return true;
    }      
    return false;        
  } 

  get chatMembers() {
    const members: any = this.textSession?.members || {};
    return Object.values(members)
        .filter((member: any) => !member.left)
        .sort(compareMembersFn);
  }

  get membersCount() {
    return this.textSession?.memberIDs.length || 0;
  }

  get membersIcon() {
    return this.membersActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  }

  get inPersonalContacts() {
    return this.customer?.personal?.includes(this.userId)
  }

  get granted() {
    return this.customer.permissions?.viewEmail
        || this.customer.permissions?.viewPhone
        || this.customer.permissions?.viewAddress
  }

  get isChatMember() {
    return !!this.textSession?.memberIDs?.includes(this.userId)
  }

  get showLeaveOption() {
    return (this.isAccepted && this.textSession?.memberIDs?.length > 2) || this.isInnerChat
  }

  get caseNoteItems() {
    return this.caseNotes.sort(this.compareFn)
  }

  get isEmptyCaseNoteItems() {
    return !this.caseNoteItems?.length
  }

  get customerNoteItems() {
    return this.customerNotes.sort(this.compareFn)
  }

  get isEmptyCustomerNoteItems() {
    return !this.customerNotes?.length
  }

  get repliesLoaded() {
    return chatStore.repliesLoaded
  }

  get notesLoaded() {
    return chatStore.notesLoaded
  }

  get isCaseNote() {
    return this.currentWindow === 'caseNotes';
  }

  get isCaseNoteReplies() {
    return this.navHistory[this.navHistory.length - 1] === 'caseNotes'
  }

  get hasCase() {
    return !!this.textSession?.case
  }

  @Action blockContact;
  @Action unblockContact;
  @Action leaveChat;

  memberMenu(member) {
    if(this.membersCount > 2){
      return [        
        {
        title: 'Delete',
        skip: member.type !== 2,
        alert: true
        },
        {
        title: 'Delete',
        skip: member.type === 2,
        alert: true
        }
      ]
    }else{
      return [
        {
          title: this.blockedUser ? 'Unblock' : 'Block',
          skip: member.type !== 2,
          alert: true
        },
        {
          title: 'Delete',
          skip: member.type === 2,
          alert: true
        }
      ]
    }    
  }

  showOptions(member) {
    if (this.mode == 'archive') {
      return false
    }
    if (member.type === 2 && !this.chatMembers.some((item: any) => item.uid === this.userId)) {
      return false
    }
    if (member.type !== 2 && this.membersCount <= 2) {
      return false
    }
    if (member.id === this.contact.id) {
      return false
    }
    return true
  }

  onMemberMenuItemClicked(menuItem, member) {
    switch (menuItem.title) {
      case 'Delete': {
        this.onDeleteChatMember(member)
        break
      }
      default:
        this.onBlockUnblockUser()
    }
  }

  notesMenuItems(note: any) {
    return [
      {id: 1, title: this.isStarred(note) ? 'Unstar' : 'Star'},
      {id: 2, title: 'Reply'},
      {id: 3, title: 'Edit', skip: note.owner.id !== this.userId},
      {id: 4, title: 'Delete', alert: true, skip: note.owner.id !== this.userId}
    ];
  }

  noteMenuItems(note: any) {
    return [
      {id: 1, title: this.isStarred(note) ? 'Unstar' : 'Star'},
      {id: 2, title: 'Delete', alert: true, skip: note.owner.id !== this.userId}
    ];
  }

  repliesMenuItems(ownerId: string) {
    return ownerId === this.userId ? [
      {id: 1, title: 'Edit'},
      {id: 2, title: 'Delete', alert: true}
    ] : [];
  }

  @Watch('caseNotes')
  onCaseNotesChanged() {
    this.noteText = ''
    this.editNoteId = null
  }

  @Watch('value')
  onValueChanged(after) {
    if (after) {
      this.currentWindow = this.windowKey
    }
  }

  @Watch('windowKey')
  onWindowKeyChanged(newKey, oldKey) {
    this.currentWindow = newKey
    switch (newKey) {
      case 'caseNotes':
        this.onCaseNotes()
        break
      case 'customerNotes':
        this.onCustomerNotes()
        break
      case 'viewPersonalData':
        this.onViewPersonalData()
        break
      default:
    }
  }

  @Watch('repliesLoaded')
  onMessageLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = () => {
      const repliesList: HTMLElement | null = document.getElementById('repliesList');
      if (repliesList) {
        repliesList.scrollTop = repliesList.scrollHeight;
      }
      chatStore.setRepliesLoaded(false);
    }
    setTimeout(callback, 10);
  }

  @Watch('notesLoaded')
  onNotesEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = () => {
      const caseNotes: HTMLElement | null = document.getElementById('caseNotes');
      if (caseNotes) {
        caseNotes.scrollTop = caseNotes.scrollHeight;
      }
      chatStore.setNotesLoaded(false);
    }
    setTimeout(callback, 10);
  }

  async onBlockUnblockUser() {
    if (!this.blockedUser) {
      this.dialogConfirmText = 'Do you want to block this user?'
      this.dialogConfirmBtn = 'Yes, block'
      this.onConfirm = async () => {
        this.dialogConfirm = false;
        const error = await this.blockContact();
        if (!!error) {
          this.showErrorToast(error);
          return
        }
        this.showToast('User has been blocked');
      }
    } else {
      this.dialogConfirmText = 'Do you want to unblock this user?'
      this.dialogConfirmBtn = 'Yes, unblock'
      this.onConfirm = async () => {
        const error = await this.unblockContact();
        this.setSelectedCustomer(null);
        if (!!error) {
          this.showErrorToast(error);
          return
        }
        this.showToast('User has been unblocked');
      }
    }
    this.dialogConfirm = true
  }

  onLeaveChat() {
    this.dialogConfirmText = 'Do you want to leave this chat?'
    this.dialogConfirmBtn = 'Yes, leave'
    this.onConfirm = async () => {
      this.dialogConfirm = false;
      const error = await this.leaveChat();
      if (!!error) {
        this.showErrorToast(error);
        return
      }
      this.showToast('You\'ve left the chat');
      const {name, query} = this.$route
      if (name) {
        // todo: check return type (promise?)
        const chatId = await chatStore.findFirstChatIdForType({
          type: query.type,
          subtype: query.subtype
        })
        console.log('onLeaveChat', name, chatId)
        if (chatId) {
          await this.$router.push({name, params: {chatId: chatId}, query});
        } else {
          if (name === 'inner-chat') {
            await this.$router.push({name: 'inner', params: {}, query});
          }
        }
      }
    };
    this.dialogConfirm = true
  }

  onScroll(evt) {
    console.log(`onScroll: offset=${evt.target.scrollHeight}`)
  }

  onClose() {
    this.show = false;
    this.navHistory = []
    this.backTitles = []
    this.currentWindow = 'info'
  }

  onBack() {
    const hist = this.navHistory.pop();
    if (!!hist) {
      this.currentWindow = hist;
      this.backTitles.pop()
      return;
    }
  }

  async onAddPersonalContact() {
    await customersStore.addToPersonalContacts(this.customer.id)
    this.showInfo('Added to My contacts')
  }

  onCustomerInfo() {
    this.$router.push({
      name: 'personal-customer-profile',
      params: {customerId: this.customer.id, type: 'personal'}
    })
  }

  senderImage(message) {
    return message?.photoUrl || require('@/assets/img/userpic.webp')
  }

  ownerImage(note) {
    return note?.owner?.photoUrl?.thumbnail || require('@/assets/img/userpic.webp')
  }

  responderImage(responder) {
    return responder?.photoUrl?.thumbnail || require('@/assets/img/userpic.webp')
  }

  replyImage(reply: any) {
    return reply.photoUrl
  }

  respondersContainerStyle(index) {
    return {
      marginRight: `${index * 16}px`
    }
  }

  responderAvatarStyle(index) {
    return {
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      marginRight: `${56 + index * 9}px`
    }
  }

  replyDate(reply) {
    return reply.createdDate?.toDate() || null
  }

  itemClicked(event) {
    switch (event) {
      case 'mute-conversation': {
        if (!!this.mutedFor) {
          this.onSaveChatNotificationsSettings()
          break
        }
        this.showMuteChatDialog = true;
        break;
      }
      case 'edit-title': {
        this.editTitle = true;
        const titleField = this.$refs.title as HTMLElement
        titleField.focus()
        break;
      }
      case 'save-title': {
        if (this.newChatTitle !== this.title
            && this.newChatTitle.replace(/\s/g, '').length > 0) {
          chatStore.changeChatTitle({chatId: this.textSession.id, title: this.newChatTitle})
        }
        this.editTitle = false;
        break;
      }
      case 'create-group': {
        this.createGroup = true
        this.showAddChatMembersAllDialog = !this.isInnerChat
        this.showAddChatMembersDialog = this.isInnerChat
        break
      }
      case 'add-chat-member': {
        this.createGroup = false
        this.showAddChatMembersAllDialog = !this.isInnerChat
        this.showAddChatMembersDialog = this.isInnerChat
        break
      }
      default:
    }
  }

  async onDeleteChatMember(member) {
    await chatStore.deleteChatMember({chatId: this.chatId, member})
  }

  toggleMembers() {
    this.membersActive = !this.membersActive;
  }

  onCaseNotes() {
    this.navHistory.push(this.currentWindow);
    this.backTitles.push(this.chatInfoTitle);
    this.currentWindow = 'caseNotes';
    this.scrollToBottom()
  }

  onCustomerNotes() {
    this.navHistory.push(this.currentWindow);
    this.backTitles.push(this.chatInfoTitle);
    this.currentWindow = 'customerNotes';
    this.scrollToBottom()
  }

  onViewPersonalData() {
    if (!this.granted) {
      this.$emit('request-personal-data')
      return
    }
    this.navHistory.push(this.currentWindow);
    this.backTitles.push(this.chatInfoTitle);
    this.currentWindow = 'viewPersonalData';
  }

  async onSaveNote() {
    this.noteText = this.noteText.trim()
    if (!this.noteText.length) {
      return;
    }
    try {
      if (this.isCaseNote) {
        await chatStore.saveCaseNote({
          noteId: this.editNoteId,
          noteText: this.noteText,
          caseId: this.textSession.case.id
        });
        this.showToast('Case note has been saved');
      } else {
        await chatStore.saveCustomerNote({
          noteId: this.editNoteId,
          noteText: this.noteText,
          caseId: this.textSession.case.id,
          customerId: this.textSession.customer?.id
        });
        this.showToast('Customer note has been saved');
      }
    } catch (err) {
      this.showErrorToast(err);
    }
    this.noteText = '';
    this.editNoteId = null;
  }

  async onSendReply() {
    this.noteReplyText = this.noteReplyText.trim()
    if (!this.noteReplyText.length) {
      return;
    }
    await chatStore.saveNoteReply({
      replyId: this.editReplyId,
      replyText: this.noteReplyText,
      caseNoteReply: this.isCaseNoteReplies
    });
    this.noteReplyText = '';
    this.editReplyId = null;
  }

  async onNoteSelected(note) {
    this.selectedNote = note;
    this.navHistory.push(this.currentWindow);
    this.backTitles.push(this.chatInfoTitle);
    this.currentWindow = 'noteReplies';
    if (!!this.unsubscribeReplies) {
      this.unsubscribeReplies()
    }
    const {selectedNote, isCaseNoteReplies} = this
    this.unsubscribeReplies = await chatStore.loadNoteReplies({note: selectedNote, isCaseNoteReplies})
    const noteEl: HTMLElement | null = document.getElementById('selectedNote');
    const repliesEl: HTMLElement | null = document.getElementById('repliesList');
    const replayInputEl: HTMLElement | null = document.getElementById('replayInput');
    if (noteEl && repliesEl && replayInputEl) {
      const height = `calc(100vh - 80px - ${replayInputEl.scrollHeight}px - ${noteEl.scrollHeight}px)`;
      repliesEl.style.height = height
      repliesEl.style.maxHeight = height
    }
  }

  onCaseNoteMenuItemSelected(menuItem, note) {
    switch (menuItem.title) {
      case 'Reply':
        this.onNoteSelected(note);
        break;
      case 'Edit':
        this.editNoteId = note.id;
        this.noteText = note.text;
        break;
      case 'Delete':
        chatStore.deleteNote(note);
        break;
      default:
        chatStore.starNote(note);
    }
  }

  onNoteMenuItemSelected(menuItem, note) {
    switch (menuItem.title) {
      case 'Delete':
        if (this.isCaseNoteReplies) {
          chatStore.deleteNote(note);
        } else {
          chatStore.deleteCustomerNote({note, customerId: this.textSession.customer?.id});
        }
        break;
      default:
        if (this.isCaseNoteReplies) {
          chatStore.starNote(note);
        } else {
          chatStore.starCustomerNote({note, customerId: this.textSession.customer?.id});
        }
    }
  }

  onCustomerNoteMenuItemSelected(menuItem, note) {
    switch (menuItem.title) {
      case 'Reply':
        this.onNoteSelected(note);
        break;
      case 'Edit':
        this.editNoteId = note.id;
        this.noteText = note.text;
        break;
      case 'Delete':
        chatStore.deleteCustomerNote({note, customerId: this.textSession.customer?.id});
        break;
      default:
        chatStore.starCustomerNote({note, customerId: this.textSession.customer?.id});
    }
  }

  onReplyMenuItemSelected(menuItem, reply) {
    switch (menuItem.title) {
      case 'Edit':
        this.editReplyId = reply.id;
        this.noteReplyText = reply.text;
        break;
      case 'Delete':
        if (this.isCaseNoteReplies) {
          chatStore.deleteCaseNoteReply(reply.id)
        } else {
          chatStore.deleteCustomerNoteReply({replyId: reply.id, customerId: this.textSession.customer?.id})
        }
        break;
      default:
    }
  }

  isStarred(note) {
    return !!(note.starred && note.starred[this.userId!]);
  }

  noteTimestamp(note) {
    return note.createdDate?.toDate()
  }

  isOnline(member) {
    const status = member.status;
    return status && status.online;
  }

  photoUrl(member) {
    const photoUrl = member && member.photoUrl;
    return photoUrl && photoUrl.normal;
  }

  showToast(message) {
    this.$emit('on-info', message);
  }

  showErrorToast(error) {
    this.$emit('on-error', error);
  }

  async onSaveChatNotificationsSettings(duration: number = 0) {
    const {chatId} = this
    await chatStore.muteChat({chatId, duration})
  }


  get hideCustomLastBorder() {
        
    
    if(!this.isHideCustom && !this.blockedUser && !this.isArchive){
      return false;
    }
    else if(!this.isHideCustom && (this.isRequest || this.isActiveChat) && !this.isArchive && !this.blockedUser && this.hasCase){
      return false;
    }
    else if(!this.isHideCustom && (this.isAccepted || this.isInnerChat) && !this.blockedUser){
      return false;
    }    
    else if(this.isAccepted || this.isRejected || this.isInnerChat || this.isArchive){
      return false;
    }
    else if(this.isAccepted && this.isActiveChat && this.isChatMember && !this.blockedUser){
      return false;
    }else{
      return true;
    }
    
  }

  beforeDestroy() {
    if (!!this.unsubscribeReplies) {
      this.unsubscribeReplies()
    }
  }

  private scrollToBottom() {
    const callback = () => {
      const caseNotes: HTMLElement | null = document.getElementById(this.currentWindow);
      if (caseNotes) {
        caseNotes.scrollTop = caseNotes.scrollHeight;
      }
      chatStore.setNotesLoaded(false);
    }
    setTimeout(callback, 10);
  }

  created() {
    this.currentWindow = this.windowKey
  }
}
