var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"kind":_vm.kind,"show-avatar":_vm.showAvatar,"message":_vm.message,"user-id":_vm.userId,"more-option":_vm.moreOption,"with-menu":_vm.withMenu},on:{"option-item-clicked":_vm.onOptionItemClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var showRight = ref.showRight;
              var messageText = ref.messageText;
              var timestamp = ref.timestamp;
              var timestampColor = ref.timestampColor;
              var timestampIcon = ref.timestampIcon;
              var timestampStyle = ref.timestampStyle;
              var color = ref.color;
              var backgroundClass = ref.backgroundClass;
              var textColorClass = ref.textColorClass;
              var textPositionClass = ref.textPositionClass;
              var message = ref.message;
              var cardStyle = ref.cardStyle;
              var paddingClass = ref.paddingClass;
return [_c('v-card',{class:[paddingClass, textColorClass, backgroundClass],style:(cardStyle),attrs:{"flat":"","max-width":"500","color":color}},[_c('v-list-item',{staticClass:"pa-0"},[_c('div',{staticClass:"mr-1",class:{'selected-left--white': showRight, 'selected-left--dark': !showRight},staticStyle:{"height":"46px"}}),(_vm.replyMessageType === 14)?_c('v-list-item-action',{attrs:{"icon":""}},[_c('v-img',{staticClass:"image-rounded",attrs:{"src":_vm.imageUrl,"max-height":"46","max-width":_vm.maxWidth}})],1):_vm._e(),_c('v-list-item-content',{staticClass:"mx-2"},[_c('v-list-item-title',{class:textColorClass},[_vm._v(_vm._s(_vm.replySender)+" ")]),_c('v-list-item-subtitle',{class:textColorClass},[_vm._v(" "+_vm._s(_vm.replyText)+_vm._s(_vm.replyMessageType === 20 ? ("(" + _vm.latitude + "," + _vm.longitude + ")") : '')+" ")])],1)],1),_c('v-card-text',{staticClass:"pa-0",class:[textPositionClass, textColorClass]},[_vm._v(" "+_vm._s(messageText)+" ")]),_c('v-card-actions',{staticClass:"pa-0 ma-0"},[_c('v-spacer'),_c('span',{style:(timestampStyle)},[_vm._v(_vm._s(timestamp))]),(showRight)?_c('v-img',{staticClass:"ml-1",attrs:{"src":require(("../../assets/img/" + timestampIcon)),"max-width":"15","max-height":"8"}}):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }