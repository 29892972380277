











































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '../messages/BaseMessage.vue';
import {lineBreaks} from '@/utils/helpers';

@Component({
  name: 'standard-message',
  components: {BaseMessage}
})
export default class StandardMessage extends Vue {
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop({default: false}) right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) forwarded?: boolean;
  @Prop({default: false}) channel?: boolean;

  get forwardedFrom() {
    return this.message.data?.forwardedMessage?.sender?.name || 'unknown'
  }

  get convertText() {
    return lineBreaks(this.message.data?.text?.wrapLinks())
  }

  get isOwner(){
    return this.message.data?.sender?.uid === this.userId;
  }

  get linkColor(){
    if(this.convertText.includes('<a class="link"') && !this.isOwner){
      return 'a'
    }
    return ''
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
