import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import store from '@/store'
import {mapsApiKey} from '@/plugins/firebase.config'
import {GoogleMapStatus} from '@/domain/model/types'
import {Loader} from '@googlemaps/js-api-loader'

@Module({name: 'google-map-module', store, dynamic: true})
export default class GoogleMapModule extends VuexModule {
    private _currentAddress: any | null = null
    private _placesService: any | null = null
    private _googleMapScriptStatus: number = GoogleMapStatus.NOT_LOADED

    get isGoogleMap(): boolean {
        return typeof google === 'object' && typeof google.maps === 'object'
    }

    get googleMapScriptStatus() {
        return this._googleMapScriptStatus
    }

    get currentAddress() {
        return this._currentAddress
    }

    get placesService(): any {
        return this._placesService
    }


    @Mutation
    public setAddress(address: any) {
        this._currentAddress = address
    }

    @Mutation
    public clearAddress() {
        this._currentAddress = null
    }

    @Mutation
    public setPlacesService(service) {
        this._placesService = service
    }

    @Mutation
    public setGoogleMapScriptStatus(status: number) {
        this._googleMapScriptStatus = status
    }

    @Action
    public async loadMap() {
        if (this.googleMapScriptStatus !== GoogleMapStatus.NOT_LOADED) {
            return
        }
        try {
            if (!this.isGoogleMap) {
                this.setGoogleMapScriptStatus(GoogleMapStatus.LOADING)
                const loader = new Loader({
                    apiKey: mapsApiKey,
                    version: 'weekly',
                    libraries: ['places']}
                )
                loader.load().then(() => {
                    this.setGoogleMapScriptStatus(GoogleMapStatus.LOADED)
                }).catch((e) => {
                    this.setGoogleMapScriptStatus(GoogleMapStatus.ERROR)
                })
            }
        } catch (err) {
            console.error(err)
        }
    }
}

export const googleMapStore = getModule(GoogleMapModule)
