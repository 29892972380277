

























import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {twoChars} from '@/utils/helpers';

@Component({
  name: 'chat-info-user',
  components: {AvatarWithStatus, GroupAvatar}
})
export default class ChatInfoUser extends Vue {
  @Prop({default: false}) group?: boolean;
  @Prop() item?: any;
  @Prop() imageUrl?: string;
  @Prop() imageUrls?: any;
  @Prop() description?: string;
  @Prop() position?: string;
  @Prop() userId?: string;
  @Prop() userName?: string;
  @Prop({default: false}) online?: boolean;
  @Prop({default: false}) selected?: boolean;
  @Prop({default: false}) showDob?: boolean;

  get name() {
    return this.userName || this.item?.name || this.item?.fullName;
  }

  get abbreviation() {
    return twoChars(this.name);
  }

  get dob() {
    return !!this.item?.permissions?.viewDOB ? this.item?.dob : null
  }

  onSelectContact() {
    const fromQuery = this.$route.fullPath; 
    if(this.item.type === 2){
      //Customer
      if (fromQuery.includes("/requests")) {
        this.$router.push({name: 'request-customer-profile', params: {customerId: this.item.id!, fromQuery: fromQuery, fromMember: 'true'}});
      }else if (fromQuery.includes("/chats")){
        this.$router.push({name: 'chat-customer-profile', params: {customerId: this.item.id!, fromQuery: fromQuery, fromMember: 'true'}});
      }else if (fromQuery.includes("/archive")){
        this.$router.push({name: 'archive-customer-profile', params: {customerId: this.item.id!, fromQuery: fromQuery, fromMember: 'true'}});
      }
    }else{
      if (fromQuery.includes("/requests")) {
        this.$router.push({ name: 'requests-contact-info', params: { contactId: this.item.id!, fromQuery: fromQuery, fromMember: 'true' } });
      }else if (fromQuery.includes("/chats")){
        this.$router.push({ name: 'chats-contact-info', params: { contactId: this.item.id!, fromQuery: fromQuery, fromMember: 'true' } });
      }else if (fromQuery.includes("/archive")){
        this.$router.push({ name: 'archive-contact-info', params: { contactId: this.item.id!, fromQuery: fromQuery, fromMember: 'true' } });
      }
    }
  }
};
