























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';
import {Action, Getter} from 'vuex-class';

@Component({
  name: 'person-added-to-chat-message',
  components: {BaseMessage}
})
export default class PersonAddedToChatMessage extends Vue {
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() members?: any | null;
  @Prop({default: true}) withMenu!: boolean;

  @Action verifyCustomerById;

  get classCard() {
    return 'test'
  }

  async onSelectContact(isSender, userType, contactId, uid) {

    const fromQuery = this.$route.fullPath;     
    if(isSender){
      if (fromQuery.includes("/requests")) {
        this.$router.push({ name: 'requests-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
      }else if (fromQuery.includes("/chats")){
        this.$router.push({ name: 'chats-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
      }else if (fromQuery.includes("/archive")){
        this.$router.push({ name: 'archive-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
      }
    }else {
      //const member = this.members[uid];
      if(contactId == uid){
        //Customer
        if (fromQuery.includes("/requests")) {
          this.$router.push({name: 'request-customer-profile', params: {customerId: contactId!, fromQuery: fromQuery, fromMember: 'true'}});
        }else if (fromQuery.includes("/chats")){
          this.$router.push({name: 'chat-customer-profile', params: {customerId: contactId!, fromQuery: fromQuery, fromMember: 'true'}});
        }else if (fromQuery.includes("/archive")){
          this.$router.push({name: 'archive-customer-profile', params: {customerId: contactId!, fromQuery: fromQuery, fromMember: 'true'}});
        }
      }else{
        if (fromQuery.includes("/requests")) {
          this.$router.push({ name: 'requests-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
        }else if (fromQuery.includes("/chats")){
          this.$router.push({ name: 'chats-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
        }else if (fromQuery.includes("/archive")){
          this.$router.push({ name: 'archive-contact-info', params: { contactId: contactId!, fromQuery: fromQuery, fromMember: 'true' } });
        }
      }
    }
    
  }
}
