


































































import Component from 'vue-class-component';
import {Prop, Vue} from 'vue-property-decorator';
import BaseMessage from '../messages/BaseMessage.vue';

@Component({
  name: 'image-message',
  components: {BaseMessage},
})
export default class ImageMessage extends Vue {
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop() right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) channel?: boolean;
  @Prop({
    default: false
  }) forwarded?: boolean;

  dialog: boolean = false
  loading: boolean = true
  error: any = ''

  get imageName() {
    return this.forwarded
        ? this.message.data.forwardedMessage.text
        : this.message.data.text;
  }

  get imageObj() {
    return this.forwarded
        ? this.message.data.forwardedMessage.image
        : this.message.data.image;
  }

  get imageUrl() {
    return this.imageObj.url;
  }

  get imageSize() {
    return this.imageObj.size
  }

  get imageRatio() {
    if (!this.imageSize) {
      return 1
    }
    const {width, height} = this.imageSize
    return width / height
  }

  get maxWidth() {
    return 380
  }

  get maxHeight() {
    return this.maxWidth / this.imageRatio
  }

  get width() {
    if (!this.imageSize) {
      return 0
    }
    const width = this.imageSize.width;
    return width > this.maxWidth ? this.maxWidth : width
  }

  get height() {
    if (!this.imageSize) {
      return 0
    }
    return this.width / this.imageRatio
  }

  get isSMS() {
    return this.message?.data?.isSMS;
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }

  onLoaded(evt: any) {
    console.log(`onLoaded => ${evt}`)
    this.loading = false
    this.error = ''
  }

  onError(evt: any) {
    console.log(`onError => ${evt}`)
    this.error = evt
  }
}
