



























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessContact, messageType, senderType} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import ContactsList from '@/components/ContactsList.vue';
import {profileStore} from '@/store/modules/profile';
import GpHeader from '@/components/custom/HeaderView.vue';
import GpTabs from '@/components/custom/TabsView.vue';
import GpTab from '@/components/custom/TabView.vue';

import {chatStore} from "@/store/modules/chat/ChatStore";
import { chat, newChatMessage } from '@/data/firebase';
import { setDoc, arrayUnion, Timestamp, updateDoc } from 'firebase/firestore';
import Notifications from '../mixins/Notifications';

@Component({
  name: 'dialog-add-chat-members-all',
  components: {ContactsList, BaseDialog,  GpHeader, GpTabs, GpTab},
})
export default class DialogAddChatMembersAll extends Notifications {
  @Prop() value!: boolean;
  @Prop({default: false}) create!: boolean;  
  chatName: string = ''
  userId?: string | null = profileStore.t2bUser?.id

  showClose: boolean = true;
  showList: boolean = false;
  groupName: string = '';
  selectedTab: number = 0;
  tabs: string[] = ['Customers', 'Directory', 'My Contacts'] 
  
  assignedDirectoryList: BusinessContact[] = []  

  selectedDirectory: BusinessContact[] = []  
  selectedCustomers: BusinessContact[] = []
  selectedPersonals: BusinessContact[] = []

  selectedAdminsDistinct: BusinessContact[] = []
  selectedCustomersDistinct: BusinessContact[] = []  

  selectedListAll: BusinessContact[] = []

  currentStep: string = 'select-customer'
  stepCustomers: string = 'select-customer'
  stepContacts: string = 'select-contact'

  @Getter businessDirectory

  
  @Getter chatsBusy
  @Getter optedInCustomers;
  @Getter customers;
  @Getter chatsError
  @Getter selectedTextSession
  

  @Action loadCustomers;
  @Action createActiveChat
  @Action addChatMembers 
  
 
  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get titleChanged() {
    const title: string = this.selectedTextSession.title ? this.selectedTextSession.title : ''
    return title !== this.chatName
  }

  get title() {
      return this.create ? 'Create group' : 'Add members'
  }

  
  get customerContacts(){
    return   
  }
  
  get getAssignedDirectoryList() {
    return this.assignedDirectoryList
  }
 
  get personalCustomers() {
    const id = profileStore.t2bUser!.id;
    return this.customers.filter((customer) => customer.personal && customer.personal.includes(id));
  }

  get toalCount(){
    let count = 0;   

    this.onAdminContactsDistinct();
    if(this.selectedAdminsDistinct.length > 0){
      count += this.selectedAdminsDistinct.length;
    } 

    this.onCustomerContactsDistinct();
    if(this.selectedCustomersDistinct.length > 0){
      count += this.selectedCustomersDistinct.length;
    } 

    return count;
  }

  onDirectorySelected(contacts: any[]) {           
    this.selectedDirectory = contacts;
  }

  onCustomerSelected(contacts: any[]) {
    this.selectedCustomers = contacts;
  }  

  onPersonalSelected(contacts: any[]){
    this.selectedPersonals = contacts;
  } 

  onAdminContactsDistinct() {
    this.selectedAdminsDistinct = this.selectedDirectory;
  }

  onCustomerContactsDistinct() {
    this.selectedCustomersDistinct = [];
    
    const tempContact = this.selectedPersonals.filter(el => {
        return !this.selectedCustomers.find(element => {
          return element.id == el.id;
        });
    });    
    
    this.selectedCustomersDistinct = [...this.selectedCustomers, ...tempContact]
  }

  onShowList(){
    this.showClose = false;
    this.showList = true;
    this.onAdminContactsDistinct();
    this.onCustomerContactsDistinct();
    this.selectedListAll =  [...this.selectedAdminsDistinct, ...this.selectedCustomersDistinct]    
  }
  
  onCloseList(){
    this.showClose = true;
    this.showList = false;
  }

  onTabChanged(index: number) {    
    this.selectedTab = index;
  }

  async navigateToChat(chat){

    try{
      const chatId = chat.id
      const isRequest = chat.case?.status === 1

      if (isRequest) {        
        await this.$router.push({
          name: 'inbox-request',
          params: {chatId},
          query: {type: 'inbox', subtype: 'personal'}
        });
      } else {        
        await this.$router.push({
          name: 'active-chat',
          params: {chatId},
          query: {type: 'active', subtype: 'personal', newChat: 'true'}
        });
      }

    }catch(err){  
      console.log(err)
    }

    return true;
  }

  get filterIDs() {      
    return (this.selectedTextSession && this.selectedTextSession.memberIDs) || []
  }

  
  async onAddChatMembers() {

    // get distinct contacts
    this.onAdminContactsDistinct();
    this.onCustomerContactsDistinct();
      

    var customers = this.selectedCustomersDistinct;
    var admins = this.selectedAdminsDistinct;

    const data = {
        sender: this.sender,
        contactIds: admins.map((item) => item.id),
        title: this.chatName
    }
    if (data.contactIds.length > 0 || customers.length > 0) {
      const addedMember = await this.addChatMembers(data);
      const addedCustomer = await this.onAddCustomers(customers)

      // if not updated
      if(this.titleChanged){
        await chatStore.changeChatTitle({chatId: this.selectedTextSession.id, title: this.chatName})
      }

      if (!!addedMember && !!addedCustomer) {
        this.show = false
      }
    } else {
      await chatStore.changeChatTitle({chatId: this.selectedTextSession.id, title: this.chatName})
      this.show = false
    }
}

  async onAddCustomers(data){
    if(data.length == 0){
        return true
    }
    try {
      return await Promise.all(data.map(async (customer) => {
        if(this.selectedTextSession.memberIDs.includes(customer.id)){
            this.showIssue('Contact Already in Group');
            return
        }
        
        await setDoc(chat(this.selectedTextSession.id), {memberIDs: arrayUnion(customer.id)}, {merge: true}).then((res) =>{
            setDoc(chat(this.selectedTextSession.id), { members : {
                [customer.id]: {
                    description: "Customer",
                    id: customer.id,
                    name: customer.fullName,
                    status: customer.status,
                    type: 2,
                    uid: customer.id
                }
            }}, { merge: true}).then((res) =>{
                //add system messgae
                let associate = this.selectedTextSession.associate
                setDoc(newChatMessage(this.selectedTextSession.id), {
                    text: associate.name + ' added ' + customer.fullName + ' to this chat',
                    sender: {name: associate.name, uid: associate.uid, contactId: associate.id, type: senderType.SYSTEM},
                    recipient: { uid: customer.id, contactId: customer.id, name: customer.fullName },
                    textSessionId: this.selectedTextSession.id,
                    type: messageType.ADD_PERSON_TO_GROUP,
                    createdDate: Timestamp.now(),
                    memberIDs: this.selectedTextSession.memberIDs
                    }, {merge: true})
            })
        });
      }));
      
    } catch (error) {
      console.error(error);
      return error
    }

  }

  get sender() {
      if (this.selectedTextSession.type === 4) { // active chat
          if (this.selectedTextSession.associate.id === this.userId) {
              return {
                  contactId: this.selectedTextSession.associate.id,
                  contactName: this.selectedTextSession.associate.name
              }
          }
      }
      const from = this.selectedTextSession.from;
      if (from.uid === this.userId) {
          return {
              contactId: from.id,
              contactName: from.name
          }
      }
      const to = this.selectedTextSession.to;
      if (to.uid === this.userId) {
          return {
              contactId: to.id,
              contactName: to.name
          }
      }
      const member = this.selectedTextSession.members[this.userId!];
      if (!!member) {
          return {
              contactId: member.id,
              contactName: member.name
          }
      }
      return null
  }

  /*onContactsSelected(contacts: BusinessContact[]) {
    this.selectedContacts = contacts;
  }*/


  created() {
    this.chatName = this.selectedTextSession.title
  }
}
