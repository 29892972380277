











































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';
import {mapLiteSettings} from '@/common/mapSettings';
import {mapsApiKey} from '@/plugins/firebase.config';
import GoogleMapSimpleLoader from '@/components/maps/GoogleMapSimpleLoader.vue';
import GoogleMapMarker from '@/components/maps/GoogleMapMarker.vue';

@Component({
  name: 'location-message',
  components: {BaseMessage, GoogleMapSimpleLoader, GoogleMapMarker}
})
export default class LocationMessage extends Vue {
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop() right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop() moreOption?: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) channel?: boolean;

  get geopoint() {
    return this.message.data.geopoint;
  }

  get mapApiKey() {
    return mapsApiKey;
  };

  get mapCenter() {
    const lat = this.geopoint.latitude;
    const lng = this.geopoint.longitude;
    return {lat, lng};
  }

  get mapConfig() {
    return {
      ...mapLiteSettings,
      center: this.mapCenter
    };
  }

  get marker() {
    return {id: 'id', position: {lat: this.mapCenter.lat, lng: this.mapCenter.lng}};
  }

  onOptionItemClicked(action, message) {
    this.$emit('option-item-clicked', action, message);
  }
}
