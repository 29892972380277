






























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BaseMessage from '@/components/messages/BaseMessage.vue';

@Component({
  name: 'personal-data-result-message',
  components: {BaseMessage}
})
export default class PersonalDataResultMessage extends Vue {
  @Prop() showAvatar?: boolean;
  @Prop() message?: any | null;
  @Prop() userId?: string;
  @Prop() kind?: boolean[];
  @Prop({default: true}) withMenu!: boolean;
}
