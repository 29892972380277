









































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {BusinessContact} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import {profileStore} from '@/store/modules/profile';
import {chatStore} from "@/store/modules/chat/ChatStore";

@Component({
  name: 'dialog-add-chat-members',
  components: {ContactsList, BaseDialog},
})
export default class DialogAddChatMembers extends Vue {
  @Prop() value!: boolean;
  @Prop({default: false}) create!: boolean;
  selectedContacts: BusinessContact[] = []
  chatName: string = ''
  userId?: string | null = profileStore.t2bUser?.id

  @Getter chatsBusy
  @Getter chatsError
  @Getter selectedTextSession

  get show() {
      return this.value;
  }

  set show(value: boolean) {
      this.$emit('input', value);
  }

  get titleChanged() {
    const title: string = this.selectedTextSession.title ? this.selectedTextSession.title : ''
    return title !== this.chatName
  }

  get title() {
      return this.create ? 'Create group' : 'Add members'
  }

  get filterIDs() {
      return (this.selectedTextSession && this.selectedTextSession.memberIDs) || []
  }

  get sender() {
      if (this.selectedTextSession.type === 4) { // active chat
          if (this.selectedTextSession.associate.id === this.userId) {
              return {
                  contactId: this.selectedTextSession.associate.id,
                  contactName: this.selectedTextSession.associate.name
              }
          }
      }
      const from = this.selectedTextSession.from;
      if (from.uid === this.userId) {
          return {
              contactId: from.id,
              contactName: from.name
          }
      }
      const to = this.selectedTextSession.to;
      if (to.uid === this.userId) {
          return {
              contactId: to.id,
              contactName: to.name
          }
      }
      const member = this.selectedTextSession.members[this.userId!];
      if (!!member) {
          return {
              contactId: member.id,
              contactName: member.name
          }
      }
      return null
  }

  @Action addChatMembers

  onContactsSelected(contacts: BusinessContact[]) {
    this.selectedContacts = contacts;
  }

  async onAddChatMembers() {
    const data = {
        sender: this.sender,
        contactIds: this.selectedContacts.map((item) => item.id),
        title: this.chatName
    }
    if (data.contactIds.length > 0) {
      const added = await this.addChatMembers(data);
      if (!!added) {
        this.show = false
      }
    } else {
      await chatStore.changeChatTitle({chatId: this.selectedTextSession.id, title: this.chatName})
      this.show = false
    }
  }

  created() {
    this.chatName = this.selectedTextSession.title
  }
}
