































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';

@Component({
    name: 'dialog-mute-time-settings',
    components: {BaseDialog}
})
export default class DialogMuteTimeSettings extends Vue {
    @Prop() value!: boolean;
    @Prop() title?: string;
    @Prop() subtitle?: string;

    selectedTime: number | null = 0
    times: any[] = [
        {name: 'None', value: 0},
        {name: 'For 15 minutes', value: 15 * 60},
        {name: 'For 1 hour', value: 60 * 60},
        {name: 'For 8 hours', value: 8 * 60 * 60},
        {name: 'For 24 hours', value: 24 * 60 * 60},
        {name: 'Until I turn it back on', value: 365 * 24 * 60 * 60}
    ];

    get show() {
        return this.value;
    }

    set show(value: boolean) {
        this.$emit('input', value);
    }

    onTimeSelected() {
        this.$emit('time-selected', this.selectedTime)
        this.show = false
    }
}
