





































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {chatStore} from '@/store/modules/chat/ChatStore';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'dialog-notification-settings',
  components: {BaseDialog}
})
export default class DialogNotificationSettings extends Vue {
  @Prop() value!: boolean;
  @Prop() chatId?: string;

  selectedTime: number | null = 0

  times: any[] = [
    {name: 'None', value: 0},
    {name: 'For 15 minutes', value: 15 * 60},
    {name: 'For 1 hour', value: 60 * 60},
    {name: 'For 8 hours', value: 8 * 60 * 60},
    {name: 'For 24 hours', value: 24 * 60 * 60},
    {name: 'Until I turn it back on', value: 356 * 24 * 60 * 60}
  ];

  get savingSettings() {
    return chatStore.saving || profileStore.savingProfile
  }

  get savingError() {
    return chatStore.error || profileStore.errorProfile
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get title() {
    return 'Mute Conversation'
  }

  get subtitle() {
    return 'Mute notifications for this conversation'
  }

  async onSaveNotificationSettings() {
    const ok = await chatStore.muteChat({chatId: this.chatId, duration: this.selectedTime})
    if (ok) {
      this.show = false
    }
  }
}
