var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-message',{attrs:{"kind":_vm.kind,"show-avatar":false,"message":_vm.message,"user-id":_vm.userId,"with-menu":_vm.withMenu,"centered":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var showRight = ref.showRight;
var timestamp = ref.timestamp;
var timestampColor = ref.timestampColor;
var timestampIcon = ref.timestampIcon;
var message = ref.message;
var messageClass = ref.messageClass;
var cardStyle = ref.cardStyle;
return [_c('v-card',{class:[messageClass],style:(cardStyle),attrs:{"flat":"","max-width":"500","color":"#eeeeee"}},[_c('v-card-text',{staticClass:"pa-0 word-wrap text-xs-center"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(message.data.sender.name))]),_vm._v(" "+_vm._s(message.data.granted ? 'grants' : 'declined')+" access to Personal Data ")]),(message.data.granted)?_c('v-card-actions',[_c('v-spacer'),_c('a',{staticClass:"btn btn-outline-primary mt-4 px-10",on:{"click":function($event){$event.preventDefault();return _vm.$emit('view-personal-data')}}},[_vm._v("Preview")]),_c('v-spacer')],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }